<script setup lang="ts">
import { PostHogEvents } from '@respell/utils/tracking/types';
import AppMarkdown from '~/components/app/AppMarkdown.vue';
import SpellRunDetails from '~/components/spell/SpellRunDetails.vue';
import { trackEvent } from '~/util/posthog';

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const spellStore = useSpellsStore();
const { template, spell, liveGraph, testRun } = storeToRefs(spellStore);

onBeforeMount(() => {
  testRun.value = undefined;
});

function useTrackedTemplate(spellId: string) {
  const spellTemplate = spellStore.duplicateSpell(spellId);

  trackEvent(PostHogEvents.UserUsedTemplate, {
    templateId: template.value?.id,
    userId: user.value?.id,
    email: user.value?.email,
  });

  return spellTemplate;
}
</script>
<template>
  <div
    class="flex justify-evenly h-full max-h-full w-full overflow-hidden px-36 gap-10"
  >
    <div
      class="flex flex-col max-w-lg py-14 w-full max-h-full overflow-y-scroll gap-2 items-start justify-start"
    >
      <app-spell-icon :spell="spell" size="l" />
      <p class="main-title">{{ spell?.name }}</p>
      <img src="/respell-social-logo.png" style="width: 5rem" />
      <AppMarkdown
        :value="spell?.description"
        max-height="500px"
        class="mt-2"
      />
      <UButton
        icon="i-ph-copy-bold"
        size="xl"
        color="primary"
        variant="solid"
        label="Use Template"
        class="mt-s"
        :ui="{
          rounded: 'rounded-full',
          font: 'font-bold',
          size: { xl: 'text-lg' },
          gap: { xl: 'gap-1.5' },
        }"
        @click="() => useTrackedTemplate(spell.id)"
      />
    </div>
    <div
      v-if="template"
      class="flex flex-col justify-center max-h-full max-w-lg w-full overflow-hidden"
    >
      <div
        class="overflow-auto flex py-12 flex-col w-full gap-5 justify-normal items-center"
      >
        <SpellFormInput
          :run="template.preview"
          :graph="liveGraph"
          size="sm"
          readonly
          class="border-x border-gray-200 rounded-2xl"
        />
        <div v-if="testRun" class="contents">
          <div class="flex flex-col gap-2 w-full">
            <SpellRunDetails :run="testRun" :graph="liveGraph" size="sm" />
          </div>
          <SpellFormOutput
            :run="testRun"
            :graph="liveGraph"
            size="sm"
            class="border-x border-gray-200 rounded-2xl"
          />
        </div>
        <span v-else class="flex gap-3">
          <UIcon name="i-respell-drawn-arrow-up" class="text-2xl" />
          <p class="body dimmed">Click here to see how it works</p>
        </span>
      </div>
    </div>
  </div>
</template>
